import {
  AppBar,
  Box,
  Button,
  Drawer,
  Icon,
  IconButton,
  Link,
  Menu,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';


// import { Link } from "react-router-dom";
import WorkIcon from '@mui/icons-material/Work';
import DrawerComponent from "./drawer";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import logo from "../img/logo-new2.png";
import { useTranslation } from "react-i18next";
import GroupsIcon from '@mui/icons-material/Groups';
import Facebook from "@mui/icons-material/Facebook";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

// const useStyles = makeStyles((theme) => ({
//   navlinks: { marginLeft: theme.spacing(10), display: "flex" },
//   logo: { flexGrow: "1", cursor: "pointer" },
const link = {
  textDecoration: "none",
  color: "inherit",
  fontSize: "1.2rem",
  "&:hover": { color: "rgb(38, 150, 203)" }
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: "absolute",
  top: 0,
  right: 0,
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor: "#000",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Navbar = () => {
  //   const classes = useStyles();
  const styleAtTop = { backgroundColor: "#00000000", boxShadow: "0px 0px 0px #00000000", color: "black", transition: "all 0.5s ease-in-out", zIndex: 1030, }
  const styleNotAtTop = { backgroundColor: "#212121", color: "black", boxShadow: "0px 0px 0px #00000000", transition: "all 0.5s ease-in-out", zIndex: 1030, }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [navbarStyle, setNavbarStyle] = useState(styleAtTop)
  const [position, setPosition] = useState("fixed")

  const [openDrawer, setOpenDrawer] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset === 0) {
        setNavbarStyle(styleAtTop)
        setPosition("fixed")

      }
      else {
        setNavbarStyle(styleNotAtTop)
        setPosition("sticky")
      }

      const event = new Event('navbarChanged')
      document.dispatchEvent(event)
    };

    return () => {
      window.onscroll = null;
    }
  }, [])


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const getImageHeight = () => {
    if (isMobile) {
      return "60em"
    }
    else {
      return "90em"
    }
  }

  return (
    <>
      <AppBar position={"fixed"} className="top-bar" sx={navbarStyle}>

        <Toolbar style={{ minHeight: "fit-content", padding: "8px 20px 1px 0px" }}>
          {
            isMobile &&
            <Tooltip title="Menu">
              <IconButton sx={{ color: "white" }} onClick={() => setOpenDrawer((prev) => !prev)} >
                {openDrawer ? <CloseIcon sx={{ color: "white", fontSize: "50px" }} /> : <MenuIcon sx={{ color: "white", fontSize: "35px", pl: "20px" }} />}
              </IconButton>
            </Tooltip>
          }
          <SwipeableDrawer anchor={"left"} open={openDrawer} onClose={() => setOpenDrawer(false)} transitionDuration={openDrawer ? 500 : 300} onOpen={() => setOpenDrawer(true)}
            PaperProps={{ sx: { backgroundColor: "#000", px: 2, width: isMobile ? "95%" : 500, maxHeight: "100vh", overflow: "hidden" } }}
          >


            <Stack direction={"row"} spacing={2}>
              <Box sx={{ width: "100%"}}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", height: "10%", marginTop: 4 }}>
                  <img src={logo} alt="logo" height={getImageHeight()} />
                </Box>
                <Stack spacing={4} justifyContent="center" alignItems={"center"} sx={{ mt: 4, height: "80%", color: "white", width: "100%" }}>
                  <Link href="#about" sx={link} onClick={() => setOpenDrawer(false)}>{t("about.title").toUpperCase()}</Link>
                  <Link href="#historique" sx={link} onClick={() => setOpenDrawer(false)}>{t("history.title").toUpperCase()}</Link>
                  <Link href="#services" sx={link} onClick={() => setOpenDrawer(false)}>{t("services.title").toUpperCase()}</Link>
                  <Link href="#projets" sx={link} onClick={() => setOpenDrawer(false)}>{t("projects.title").toUpperCase()}</Link>
                  {
                    isMobile &&
                    <>
                      <Link href="#carrieres" sx={link} onClick={() => setOpenDrawer(false)}>{t("careers.title").toUpperCase()}</Link>
                      <Link href="#equipe" sx={link} onClick={() => setOpenDrawer(false)}>{t("our team").toUpperCase()}</Link>
                      <Link href="#contact" sx={link} onClick={() => setOpenDrawer(false)}>{t("contact.title").toUpperCase()}</Link>
                    </>
                  }
                </Stack>
              </Box>

              <Box sx={{ height: "100vh", backgroundColor: "#000", zIndex: 1000, width: "90px", display: "flex", cursor: "pointer", justifyContent: "end", pl: 8 }} onClick={() => setOpenDrawer(!openDrawer)}>
                <Stack sx={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", m: "auto" }} spacing={1} direction="column">

                  <CloseIcon sx={{ color: "white", fontSize: "50px", visibility: openDrawer ? "visible" : "hidden" }} />
                  <Typography variant="body1" sx={{ color: "white", visibility: openDrawer ? "visible" : "hidden" }}>MENU</Typography>

                </Stack>
              </Box>
            </Stack>
          </SwipeableDrawer>
          <a href="/" style={{ display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "inherit", padding: "4px", marginLeft: isMobile ? "10px" : "90px", width: isMobile ? "100%" : "fit-content" }}>
            <img src={logo} alt="logo" height={getImageHeight()} />
          </a>


          <div style={{ marginLeft: "auto", display: "flex" }}>


            <Stack direction="row" spacing={2} alignItems={"center"} justifyContent={"end"}>
              {
                !isMobile &&
                <>
                  <Tooltip title={t("careers.title").toUpperCase()}>
                    <Icon component={"a"} href="#carrieres">
                      <WorkIcon sx={{ color: "white" }} />
                    </Icon>
                  </Tooltip>
                  <Tooltip title={t("our team").toUpperCase()}>
                    <Icon component={"a"} href="#equipe">
                      <GroupsIcon sx={{ color: "white" }} />
                    </Icon>
                  </Tooltip>
                  <Tooltip title={t("contact.title").toUpperCase()}>
                    <Icon component={"a"} href="#contact">
                      <LocalPhoneIcon sx={{ color: "white" }} />
                    </Icon>
                  </Tooltip>
                  <Tooltip title="Facebook">
                    <Icon component={"a"} href="https://www.facebook.com/lemayoutillage" target="_blank" sx={{ color: "white" }}>
                      <Facebook sx={{ color: "rgb(38, 150, 203)" }} />
                    </Icon>
                  </Tooltip>
                </>
              }
              {
                (i18n.language.substring(0, 2) === "fr") ?
                  <Button onClick={() => changeLanguage("en")} sx={{ color: "white" }}><Typography variant="body1">EN</Typography></Button>
                  :
                  <Button onClick={() => changeLanguage("fr")} sx={{ color: "white" }}><Typography variant="body1">FR</Typography></Button>
              }
            </Stack>
          </div>
        </Toolbar >
      </AppBar >
      {
        !isMobile &&
        <Box sx={{ height: "100vh", position: "fixed", top: 0, left: 0, backgroundColor: "#000", zIndex: 1000, width: "90px", display: "flex", cursor: "pointer" }} onClick={() => setOpenDrawer(true)}>
          <Stack sx={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", m: "auto" }} spacing={1} direction="column">
            {openDrawer ? <CloseIcon sx={{ color: "white", fontSize: "50px" }} /> : <MenuIcon sx={{ color: "white", fontSize: "50px" }} />}
            <Typography variant="body1" sx={{ color: "white" }}>MENU</Typography>
          </Stack>
        </Box>
      }
    </>

  );
}

export default Navbar;
