import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";

export const useCookies = () => {

    const [cookieConsent, setCookieConsent] = useState(undefined)

    const consentRef = useRef(cookieConsent)

    useEffect(() => {
        consentRef.current = cookieConsent
    }, [cookieConsent])

    useEffect(() => {
        const cookieConsent = localStorage.getItem("cookieConsent")
        if (cookieConsent === "true") {
            setCookieConsent(true)
        } else if (cookieConsent === "false") {
            setCookieConsent(false)
        }
        else {
            setCookieConsent(undefined)
        }
    }
        , [])

    useEffect(() => {
        if (consentRef.current === true) {
            localStorage.setItem("cookieConsent", "true")
        } else if (consentRef.current === false) {
            localStorage.removeItem("cookieConsent")
        }
    }
        , [consentRef.current])

    useEffect(() => {
        if (consentRef.current === true) {
            document.cookie = "cookieConsent=true; max-age=31536000; path=/"
        } else {
        }
    }
        , [consentRef.current])

    // useEffect(() => {


    const getCookie = (name) => {
        const cookies = new Cookies();
        return cookies.get(name)
    }

    const setCookie = (name, value, options) => {
        if (consentRef.current === true) {
            const cookies = new Cookies();
            cookies.set(name, value, options)
        }
    }

    const removeCookie = (name, options) => {
        const cookies = new Cookies();
        cookies.remove(name, options)
    }

    const getAllCookies = () => {
        const cookies = new Cookies();
        return cookies.getAll()
    }

    const handleCookieConsent = (value = true) => {
        setCookieConsent(value)
        if (value) {
            localStorage.setItem("cookieConsent", value.toString())
        }
        else {
            localStorage.removeItem("cookieConsent")
        }
    }

    return { getCookie, setCookie, removeCookie, getAllCookies, handleCookieConsent, cookieConsent }

}