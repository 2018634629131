import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar
} from "@mui/material";
import React, { useState } from "react";
import logo from "../img/logo.png";

// const useStyles = makeStyles(() => ({
//   link: { textDecoration: "none", color: "blue", fontSize: "20px" },
//   icon: { color: "white" },
//   logo: { flexGrow: "1", cursor: "pointer" },
//   draw: { background: "red" }
// }));

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        anchor="top"
        sx={{ width: 250, color: "#fff" }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Toolbar sx={{ backgroundColor: "#555555" }}>
            <img src={logo} alt="logo" width={40}/>

          <CloseIcon />
        </Toolbar>
        <box sx={{ backgroundColor: "#555555" }} height="100vh">
          <List height="100vh">
            <Divider />
            {/* <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/" >
                  首页
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/" >
                  关于
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/" >
                  联系
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/" >
                  FAQ
                </Link>
              </ListItemText>
            </ListItem>
            <Divider /> */}
          </List>
        </box>
      </Drawer>
      <IconButton
        
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default DrawerComponent;
