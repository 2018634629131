import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        fr: {
            translation: {
                "login.error": "Aucun compte actif avec cet identifiant et ce mot de passe",
                "login.username": "Nom d'utilisateur",
                "login.password": "Mot de passe",
                "login.title": "Connexion",
                "login.submit": "Connexion",
                "PageNotFound.title": "Page introuvable",
                "PageNotFound.message": "La page que vous cherchez n'existe pas ou a été déplacée.",
                "PageNotFound.button": "Retourner à l'accueil",
                "expertise": "Expertise",
                "services": "Services",
                "solutions": "Solutions",
                "business": "d'entreprise",
                "header.callToAction": "Qui sommes-nous ?",
                "about.title": "À propos",
                "about.subtitle": "Nous offrons un service rapide, personnalisé et de haute qualité.",
                "about.p1": "Lemay Outillage est un leader dans l'aide à la conception et dans la fabrication de produits d’usinage.",
                "about.p2": "Contactez-nous ! L’équipe de Lemay Outillage saura vous soutenir et vous conseiller dans la réalisation de tous vos projets d’usinage.",
                "mission.title": "Notre mission",
                "mission.p1": "Répondre efficacement aux besoins spécifiques de manufacturiers de partout dans le monde en fabriquant des produits à haute valeur et en priorisant une approche personnalisée basée sur la confiance.",
                "values.title": "Nos valeurs",
                "values.value1": "Être à l’écoute",
                "values.value1.text": "Nous accordons une importance capitale à l’écoute et au maintien des bonnes relations. Être à l’écoute nous permet de trouver des solutions aux problèmes de nos clients et de mettre à profit l’expérience, les idées et les connaissances de nos collègues.",
                "values.value2": "Attitude positive",
                "values.value2.text": "Chacun contribue, à sa façon, à créer et à maintenir un climat de travail agréable et enrichissant. Chaque personne est reconnue. Nos différences sont vues comme de belles façons d’avancer.",
                "values.value3": "Respect",
                "values.value3.text": "Le respect est au cœur de notre philosophie et étroitement lié à nos succès. Chaque jour, nous maintenons des relations courtoises avec nos clients, nous fournisseurs et nos collègues.",
                "values.value4": "Capacité d'adaptation",
                "values.value4.text": "Nous démontrons notre capacité à changer rapidement de vitesse ou de cap. Ceci se traduit par notre grande flexibilité. Nous ajustons nos façons de faire pour être proactifs et efficaces; nous sommes des gens de cœur et de rigueur.",
                "values.value5": "Confiance",
                "values.value5.text": "Nous priorisons une saine communication ouverte et nous nous accordons le droit à l’erreur dans le non jugement. Nous savons qu’une équipe forte se bâtit grâce à l’ouverture, à la transparence et à l’intégrité.",
                "differences.value1": "Conception",
                "differences.value1.text": "Lemay Outillage est une entreprise qui se spécialise dans l'aide à la conception et dans la fabrication d’outillage, comme les gabarits, poinçons et matrices, ainsi que la fabrication d’outils d’assemblage pour les domaines de l’aéronautique, des produits récréatifs, de l’automobile et de l’énergie. Nous offrons également la fabrication de pièces unitaires et de série.",
                "differences.value2": "Équipement",
                "differences.value2.text": "Notre vaste parc d’équipement d’usinage nous permet de fabriquer des produits d’usinage de qualité, sans négliger l’esthétique. Notre équipe professionnelle et dévouée de près de 50 employés offre un service personnalisé, efficace et engagé. L’entreprise est d’ailleurs reconnue pour son dévouement, sa flexibilité et son approche humaine, de même que pour son efficacité à travailler dans l’urgence.", 
                "differences.value3": "International",
                "differences.value3.text":"Nous desservons actuellement des entreprises situées au Québec, au Canada, aux États-Unis, en Europe, au Moyen-Orient et en Asie. Notre atelier d’usinage est situé en plein cœur de l’Estrie, ce qui permet l’expédition en moins de 24 heures partout au Québec. L’entreprise est certifiée ISO 9001 et CWB 47.1 pour la soudure.",
                "history.title": "Historique",
                "history.subtitle": "Le leader dans l'aide à la conception et dans la fabrication de produits d’usinage.",
                "history.p1": "Lemay Outillage a été fondé en 1979 à Bonsecours, au Québec, par Jocelyn Lemay. Dès sa création, l’entreprise se démarque par ses idées novatrices. Sa position géographique centrale lui permet de recruter du personnel dans plusieurs villes et villages aux alentours. Puis, Jocelyn Lemay sait transmettre son savoir-faire et sa passion pour son métier, tout en établissant des liens privilégiés avec ses employés. Le résultat est un taux de rétention du personnel élevé et des conditions de travail enviables. C’est ainsi qu’une entreprise familiale à l’échelle internationale est née.",
                "history.p2":"Depuis les années 1980, Lemay Outillage est en croissance constante. L’atelier d’usinage qui prenait place au départ dans la résidence familiale connaît quatre phases d’agrandissement, depuis sa construction en 1990, afin d’offrir un milieu de travail agréable, adéquat et sécuritaire à une équipe qui ne cesse d’agrandir. L’entreprise compte aujourd’hui près d’une cinquantaine d’employés répartis sur trois quarts de travail (de jour, de soir et de fin de semaine). Dans le but de rester compétitif et d’offrir un service adapté aux besoins de ses clients, Lemay Outillage agrandit continuellement son parc d’équipement grâce à l’acquisition de machines d’usinage de pointe.",
                "history.p3":"Après 32 ans à se consacrer pleinement à leur entreprise, Jocelyn Lemay et son épouse cherchent à passer le relais. En octobre 2011, l’ingénieur Hugues Maltais devient copropriétaire de Lemay Outillage. Leur fille Evelyne, qui est impliquée à temps plein dans l’entreprise depuis 2003 et qui a touché à pratiquement tous les secteurs, se joint à Hugues pour la gestion de l’entreprise en 2019. Depuis, Hugues Maltais assure le développement des affaires et le volet financier, pendant qu’Evelyne occupe le poste de directrice des opérations et des ressources humaines.",

                "services.title": "Services",
                "services.subtitle1": "Une grande gamme de services",
                "services.subtitle2": "pour tous vos besoins",
                "services.service1": "Usinage conventionnel et rectification",
                "services.service1.text": "Grâce à notre parc d’équipements diversifié (fraiseuse, tour, rectifieuse) et à notre équipe expérimentée, Lemay Outillage peut répondre à tous vos besoins en usinage conventionnel et en rectification de pièces, et ce, dans les plus brefs délais.",
                "services.service2": "Usinage CNC",
                "services.service2.text": "Notre atelier d’usinage dispose de la technologie de commande numérique par ordinateur (CNC) afin de réaliser tous vos projets d’usinage multiaxes, petits ou grands volumes, le tout en respectant les plus hautes exigences de l’industrie et en surpassant vos attentes.",
                "services.service3": "Découpage par étincelage à fil (Wire Cut)",
                "services.service3.text": "Nos trois machines à découpage par étincelage à fil (EDM) exécutent des coupes précises. Ce procédé offre une grande fiabilité et une excellente précision (jusqu’à 0,0001 de tolérance). C’est la technologie idéale pour la fabrication de poinçons, de matrices et de pièces de haute précision. Les matières conductibles, comme les aciers doux, les aciers trempés, l’aluminium et le bronze, se travaillent particulièrement bien avec ce procédé.",
                "services.service4": "Assemblage mécanosoudé",
                "services.service4.text": "Vous cherchez un service clé en main ? Grâce à notre accréditation CWB (Canadian Welding Bureau), nous pouvons vous offrir un service d’assemblage mécanosoudé en acier, en acier inoxydable ou en aluminium pour tous vos projets, même les plus complexes. Aucun projet n’est impossible à réaliser.",
                "services.service5": "Outillage",
                "services.service5.text": "Créative et innovante, l’équipe de Lemay Outillage saura donner un second souffle à votre outillage en vous proposant des solutions novatrices de réparation et de modifications d’outils. Nos procédés permettent de déterminer la meilleure technique à utiliser selon vos besoins. Résultat, un travail rapide et respectueux de la qualité des outils qui nous sont confiés.",
                "services.service6": "Développement et prototype",
                "services.service6.text": "Pour sauver du temps et de l’argent lors de la production de vos pièces mécaniques, faites appel à notre équipe passionnée et à nos équipements à la fine pointe de la technologie. Trouver des façons de faire personnalisées et innovantes pour tous les projets d’usinage, de développement et de prototypage de nos clients est l’une de nos forces.",
                "services.service7": "Impression 3D",
                "services.service7.text": "La production de pièces grâce à l’impression 3D est un plus pour la fabrication de prototypes ou de pièces en petites séries sur mesure. Elle est idéale pour la réalisation de pièces complexes qui sont difficiles, voire impossibles, à usiner.",
                "services.service8": "Poinçons et matrices",
                "services.service8.text": "Lemay Outillage offre le service de fabrication d’outils à matriçage simple et à matriçage progressif, tout en ayant la flexibilité d’exécuter des travaux de précision, sur commande, selon vos critères. Notre équipe expérimentée et notre équipement de pointe nous permettent de valider vos projets afin d’assurer leur qualité et leur bon fonctionnement.",
                "services.service9": "Inspection CMM",
                "services.service9.text": "Nous sommes en mesure de garantir la précision de vos pièces ou gabarits en les certifiant sur place grâce à notre machine à mesurer tridimensionnelle CMM (Coordinate Measuring Machine). Il est également possible d’obtenir un rapport détaillé de toutes les dimensions critiques de vos pièces pour en assurer la qualité.",
                "services.service10": "Traitement thermique et de surface",
                "services.service10.text": "Certains projets nécessitent un traitement thermique et de surface. Peinture, black oxyde, traitement de zinc (zingage), anodisation… Laissez-nous en prendre soin pour vous !",
                "services.service11": "Gabarits",
                "services.service11.text": "Pour le développement de modèles ou la fabrication de gabarits spécialisés, pensez Lemay Outillage. Notre équipe professionnelle évaluera vos besoins et vous proposera des solutions ingénieuses pour tous vos:",
                "gabarits de montage": "gabarits de montage",
                "gabarits d'assemblage": "gabarits d'assemblage",
                "gabarits de maintien": "gabarits de maintien",
                "gabarits de soudure": "gabarits de soudure",
                "gabarits d'inspection": "gabarits d'inspection",
                "gabarits de contrôle qualité": "gabarits de contrôle qualité",

                "projects.title": "Réalisations",
                "projects.subtitle": "Quelques projets",
                "aeronautical": "Aéronautique",
                "military": "Militaire",
                "energy": "Énergie",
                "packaging": "Emballage",
                "shipping": "Transport",
                "projects.p1": "Voyez quelques-unes des réalisations de Lemay Outillage,",
                "projects.p2": "le leader dans l'aide à la conception et dans la fabrication de produits d’usinage.",
                "close": "Fermer",

                "careers.title": "Carrières",
                "careers.subtitle": "Faites partie de l'équipe !",
                "careers.p1": "Consultez la liste des emplois disponibles au sein de Lemay Outillage et envoyez votre candidature en ligne pour rejoindre notre équipe dynamique.",
                "Job offer": "Offre d'emploi",
                "view offer": "Voir l'offre",

                "name": "Nom",
                "email": "Courriel",
                "phone": "Téléphone",
                "message": "Message",
                "choose a job": "Choisir un poste",
                "please select an item in the list": "Please select an item in the list",
                "send": "Envoyer",
                "resume": "C.V.",
                "please insert a valid email": "Veuillez insérer un courriel valide",
                "please insert a valid phone number": "Veuillez insérer un numéro de téléphone valide",
                "drag and drop your resume": "Faites glisser votre C.V.",
                "or": "ou",
                "upload your file": "Téléverser votre C.V.",
                "delete": "Supprimer",
                "success": "Votre message a été envoyé avec succès !",
                "error": "Une erreur est survenue, veuillez réessayer plus tard.",

                "our team": "Notre équipe",

                "contact.title": "Contact",
                "contact.subtitle": "Écrivez-nous",
                "contact.p1": "Contactez-nous pour toutes demandes d’information. Consultez la liste des employés et leurs coordonnées.",
                "follow us": "Suivez-nous",

                "footer.allRightsReserved": "Tous droits réservés",

                
            }
        },
        en: {
            translation: {
                "login.error": "No active account found with the given credentials",
                "login.username": "Username",
                "login.password": "Password",
                "login.title": "Login",
                "login.submit": "Login",
                "PageNotFound.title": "Page not found",
                "PageNotFound.message": "The page you were looking for does not exist or has been moved.",
                "PageNotFound.button": "Return to home",
                "expertise": "expertise",
                "services": "services",
                "solutions": "solutions",
                "business": "business",
                "header.callToAction": "Who are we ?",
                "about.title": "About us",
                "about.subtitle": "We offer fast, custom and quality service.",
                "about.p1": "Lemay Outillage is a leader in the design and manufacture of high-precision machined products.",
                "about.p2": "Call us ! Our qualified team will be happy to advise you throughout your project.",
                "mission.title": "Our mission",
                "mission.p1": "Respond quickly and effectively to the specific needs of companies worldwide by manufacturing high-value products and prioritizing a personalized, trust-based approach.",
                "values.title": "Our values",
                "values.value1": "Listening",
                "values.value1.text": "Listening and cultivating excellent customer relationships are of prime importance at Lemay Outillage. We get a detailed idea of your needs, then leverage our colleagues’ experience, ideas and know-how to find you the best solution.",
                "values.value2": "Positivity",
                "values.value2.text": "Each of us in our own way actively contributes to creating and maintaining a pleasant, rewarding work atmosphere. Everyone’s input is recognized. We see our differences as a starting point for finding common ground.",
                "values.value3": "Respect",
                "values.value3.text": "At the heart of our outlook and a key component of our success is respect. Each day, we strive to maintain courteous, considerate relations with our customers, suppliers and colleagues.",
                "values.value4": "Adaptability",
                "values.value4.text": "In a word, we’re flexible. We adjust our methods to stay proactive and efficient, and as needed, can quickly change gears or take a new tack.",
                "values.value5": "Trust",
                "values.value5.text": "We know that the bywords of a strong team are openness, transparency and integrity. We prize healthy, open communication and seeing mistakes as a chance to learn.",
                "differences.value1": "Design",
                "differences.value1.text": "Lemay Outillage specializes in designing and manufacturing a wide range of tools and parts, including jigs, punches and dies, aerospace assembly tools, recreational products and parts for the automotive and energy industries. We also manufacture one-off pieces and sets of parts.",
                "differences.value2": "Equipment",
                "differences.value2.text": "Thanks to our vast fleet of equipment, we can produce quality machined products that put equal emphasis on aesthetics. Our team of some 50 professionals offers personalized, efficient and dedicated service. We’re known for our commitment, flexibility and customer focus as well as our ability to respond swiftly to urgent requests.", 
                "differences.value3": "International",
                "differences.value3.text":"We currently serve companies in Québec, Canada, the U.S., Europe, the Middle East and Asia. Our Estrie location lets us guarantee 24-hour shipping to anywhere in Québec. Lemay Outillage is proudly certified ISO 9001 and CWB 47.1 for welding.",
                "history.title": "History",
                "history.subtitle": "The leader in the design and manufacture of high-precision machined products.",
                "history.p1": "Marked by an innovative outlook from the get-go, Lemay Outillage was founded by Jocelyn Lemay in 1979. Its central location in Bonsecours, Québec made it easy to recruit skilled workers from the nearby towns and villages, and Mr. Lemay’s enthusiasm and knack for sharing his expertise earned him the loyalty of his staff. To this day, the company boasts a remarkable retention rate and enviable working conditions. Thus was born an international family business.",
                "history.p2":"Lemay Outillage has been in constant growth since the 1980s. Initially operating from home, the company moved to new premises in 1990. The shop has since expanded four times, the better to offer a pleasant, appropriate and safe working environment to an ever-growing team. Today, Lemay Outillage has a workforce of close to 50 employees who cover three shifts (days, evenings and weekends). To stay competitive and meet its customers’ needs, the company continuously updates its machining systems.",
                "history.p3":"After 32 years of untiring devotion to their business, Mr. Lemay and his wife were looking to pass the baton. In October 2011, engineer Hugues Maltais became a co-owner. The Lemays’ daughter Evelyne, a full-time employee since 2003 with experience in just about every area of the business, joined Hugues at the company’s helm in 2019. Since then, he has overseen business development and finance while she takes care of operations and human resources.",

                "services.title": "Services",
                "services.subtitle1": "Services to meet",
                "services.subtitle2": "your every need",
                "services.service1": "CONVENTIONAL MACHINING AND GRINDING",
                "services.service1.text": "With our state-of-the-art equipment (milling, turning and grinding machines) and highly experienced team, we can meet all your conventional machining and grinding needs within the shortest possible turnaround time.",
                "services.service2": "CNC MACHINING",
                "services.service2.text": "We also offer CNC (computer numerical control) technology for your multiaxis machining projects. Whatever your volume needs, we will work to the highest industry standards as we set out to surpass your expectations.",
                "services.service3": "WIRE CUT EDM",
                "services.service3.text": "Our three wire-cut electric discharge machines (EDM) produce very accurate cut lines. The immense flexibility and extraordinary precision (up to 0.0001\" tolerance) of this process makes it ideal for designing and manufacturing punches, dies and high-precision parts. Conductive materials like mild and tempered steels, aluminum and bronze respond particularly well to wire cutting.",
                "services.service4": "WELDED ASSEMBLIES",
                "services.service4.text": "Looking for a turnkey service ? Thanks to our CWB (Canadian Welding Bureau) certification, we can offer a welded assembly service in steel, stainless steel or aluminum for all your projects, no matter how complex. No request is too difficult.",
                "services.service5": "TOOLMAKING",
                "services.service5.text": "Let us breathe new life into your equipment through our innovative tool repair and modification solutions. Once we’ve identified your needs, we quickly determine the best techniques to apply. The result is prompt service and a job well done that extends the lifespan and preserves the quality of your tools.",
                "services.service6": "DEVELOPMENT AND PROTOTYPING",
                "services.service6.text": "Our dedicated team and advanced facilities can save you time and money during the design and manufacture of your mechanical parts. Indeed, coming up with custom, innovative solutions for our customers’ machining, development and prototyping needs is one of our great strengths.",
                "services.service7": "3D PRINTING",
                "services.service7.text": "3D printing is a decided advantage when it comes to custom manufactured prototypes or limited series of parts. It’s also ideal for manufacturing complex parts that are difficult if not impossible to machine.",
                "services.service8": "PUNCHES AND DIES",
                "services.service8.text": "We have the capacity to run production stampings in line and progressive die configurations. Our work is precise, made to order and in keeping with your specifications. Our experienced team and advanced equipment let us create quality tools that work perfectly.",
                "services.service9": "CMM INSPECTION",
                "services.service9.text": "The precision of your parts or templates can be certified onsite with our coordinated measuring machine (CMM). We can also guarantee the quality of your parts with a detailed report of their critical dimensions.",
                "services.service10": "HEAT AND SURFACE TREATMENT",
                "services.service10.text": "Some projects call for heat and surface treatment. Painting, black oxide, zinc coating (galvanizing), anodizing . . . Let us take care of it for you !",
                "services.service11": "TEMPLATES",
                "services.service11.text": "For developing models or manufacturing specialized templates, think Lemay Outillage. Our team of professionals will assess your needs and propose ingenious solutions for your:",
                "gabarits de montage": "Jig fixtures",
                "gabarits d'assemblage": "Assembly jigs",
                "gabarits de maintien": "Maintenance jigs",
                "gabarits de soudure": "Welding jigs",
                "gabarits d'inspection": "Inspection gauges",
                "gabarits de contrôle qualité": "Quality control gauges",
                
                "projects.title": "Achievements",
                "projects.subtitle": "Some of our projects",
                "aeronautical": "Aeronautical",
                "military": "Military",
                "energy": "Energy",
                "packaging": "Packaging",
                "shipping": "Transport",
                "projects.p1": "Have a look at some of our achievements.",
                "projects.p2": "Lemay Outillage is a leader in the design and manufacture of high-precision machined products.",
                "close": "Close",

                "careers.title": "Careers",
                "careers.subtitle": "Join our team !",
                "careers.p1": "Want to be part of a dynamic team ? Browse the available jobs at Lemay Outillage and submit your application online.",
                "view offer": "View offer",
                "Job offer": "Job offer",

                "name": "Name",
                "email": "Email",
                "phone": "Phone",
                "message": "Message",
                "choose a job": "Choose a job",
                "please select an item in the list": "Please select an item in the list",
                "send": "Send",
                "resume": "Resume",
                "please insert a valid email": "Please insert a valid email",
                "please insert a valid phone number": "Please insert a valid phone number",
                "drag and drop your resume": "Drag and drop your resume",
                "or": "or",
                "upload your file": "Click to upload your resume",
                "delete": "Delete",
                "success": "Your message has been sent successfully",
                "error": "An error has occurred, please try again later.",

                "our team": "Our team",

                "contact.title": "Contact",
                "contact.subtitle": "Contact us",
                "contact.p1": "Contact us for more information. Consult the list of employees and their contact details.",
                "follow us": "Follow us",

                "footer.allRightsReserved": "All rights reserved",
                

            }
        }
    },
});

export default i18n;