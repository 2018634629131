import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, Typography, Stack, Paper, Grid, Container, Box, Divider, FormControlLabel, Switch, Skeleton, Snackbar, Tooltip, IconButton, List, ListItem, ListItemButton, Alert } from '@mui/material';
import useCookieConsent from "./useCookieConsent";
import { useCookies } from "../useCookies";

const CookieBanner = (props) => {

    const { cookieConsent, handleCookieConsent } = useCookies()
        
    if (cookieConsent !== undefined) {
        return null
    }

    return (
        <Snackbar open={!cookieConsent}  anchorOrigin={{ vertical: "bottom", horizontal: "right" }} sx={{maxWidth:"400px"}}>
            <Paper sx={{ width: '100%', backgroundColor:"white", border:"1px solid #E0E0E0", p:2, pt:3 }} elevation={20}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Typography variant="body1" align="center">
                        Ce site utilise des cookies pour améliorer votre expérience. En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies.
                    </Typography>
                    <Typography variant="body1" align="center">
                        <a href="/conditions#cookies">Conditions d'utilisation et politique de cookies</a>
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button variant="contained" onClick={() => handleCookieConsent(true)}>Accepter</Button>
                        {/* <Button variant="outlined" onClick={() => handleCookieConsent(false)}>Refuser</Button> */}
                        {/* <Button variant="outlined" onClick={() => handleCookieConsent(false)}>Refuser</Button> */}
                    </Stack>
                </Stack>
            </Paper>
        </Snackbar>
    )
}
export default CookieBanner