import React, { lazy, useEffect } from "react";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Navbar from "./component/navbar";

import './App.css'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme, responsiveFontSizes, useMediaQuery } from "@mui/material";
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CookieBanner from "./component/cookiebanner/CookieBanner";
import ScrollToHashElement from "./component/ScrollToHashElement";
import { useTranslation,  } from 'react-i18next';
import Cookies from 'universal-cookie';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import AppDrawer from "./component/AppDrawer";

let theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(38, 150, 203)',
    },
    secondary: {
      main: '#ff0077',
    },
  },
});

const HomeView = lazy(() => import('./page/Home'));
const View404 = lazy(() => import('./page/View404'));
const UserConditionsPage = lazy(() => import('./page/UserConditionsPage'));
const queryClient = new QueryClient()

const App = (props) => {
  const defaultTheme = {...theme};
  // theme = responsiveFontSizes(theme);

  theme.typography.h1 = {
    "fontFamily": "\"Monserrat\", sans-serif",
    "fontWeight": 300,
    "fontSize": "56px",
    "lineHeight": 1.167,
    "letterSpacing": "-0.01562em",
    "@media (min-width:600px)": {
      "fontSize": "2.7849rem"
    },
    "@media (min-width:900px)": {
      "fontSize": "3.2134rem"
    },
    "@media (min-width:1200px)": {
      "fontSize": "3.4276rem"
    }
  };

  theme.typography.h2 = {
    ...defaultTheme.typography.h2,
    "fontFamily": "\"Roboto\", sans-serif",
    fontSize: '20px',
    color: 'rgb(72, 74, 70)',
  };

  theme.typography.h3 = {
    ...defaultTheme.typography.h3,
    "fontFamily": "\"Roboto\", sans-serif",
    fontSize: '22px',
    color: 'rgb(72, 74, 70)',
  };

  theme.typography.h4 = {
    ...defaultTheme.typography.h4,
    "fontFamily": "\"Roboto\", -apple-system,",
    color: 'rgb(72, 74, 70)',
    fontSize: '20px',
    fontWeight: 700,
  };

  theme.typography.h5 = {
    ...defaultTheme.typography.h5,
    "fontFamily": "\"Roboto\", sans-serif",
    color: 'rgb(120, 121, 118)',
    fontSize: '18px',
  };

  theme.typography.h6 = {
    ...defaultTheme.typography.h6,
    "fontFamily": "\"Roboto\", sans-serif",
  };

  theme.typography.subtitle1 = {
    ...defaultTheme.typography.subtitle1,
    "fontFamily": "\"Montserrat\", sans-serif",
    fontSize: '13px',
    fontWeight: 500,
  };

  theme.typography.subtitle2 = {
    ...defaultTheme.typography.subtitle2,
    "fontFamily": "\"Montserrat\", sans-serif",
    fontSize: '13px',
    fontWeight: 500,
  };

  theme.typography.body1 = {
    ...defaultTheme.typography.body1,
    "fontFamily": "\"Open Sans\", sans-serif",
    color: 'rgb(120, 121, 118)',
    fontSize: '16px',
    fontWeight: 300,

  };

  theme.typography.body2 = {
    ...defaultTheme.typography.body2,
    "fontFamily": "\"Roboto\", sans-serif",
  };


  const history = createBrowserHistory();

  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const cookies = new Cookies();
    const lang = cookies.get('lang');
    if (lang) {
      i18n.changeLanguage(lang)
    }
    else {
      i18n.changeLanguage(
        window.navigator.language || window.navigator.userLanguage || 'fr'
      )
    }
  }, [])
  
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('lang', i18n.language, { path: '/' });
    document.documentElement.lang = i18n.language;

  }, [i18n.language])

  const links = [
    { text: t('about.title'), path: '#about', type: 'link'},
    { text: t('history.title'), path: '#historique', type: 'link'},
    { text: t('services.title'), path: '#services', type: 'link'},
    { text: t('projects.title'), path: '#projects', type: 'link'},
  ]

  return (
    <QueryClientProvider client={queryClient}>
    <GoogleReCaptchaProvider reCaptchaKey="6LfqNz8oAAAAACtfN9NX6JHBPIlqBYvZq6yuZG4t" >
      <BrowserRouter history={history}>
        <Navbar />
        <CookieBanner />
        <div style={{marginLeft:isMobile? "0px": "90px"}}>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route exact path="/" element={<HomeView />} />
              <Route exact path="/conditions" element={<UserConditionsPage />} />
              {/* <Route exact path="/msp/get-a-quote" element={<GetAQuoteMSP />} />
              <Route exact path="/msp" element={<DetailsMSP />} />
              <Route exact path="/software-development" element={<DetailsSoftware />} /> */}
              <Route path="*" element={<View404 />} />
            </Routes>
          </ThemeProvider>
        </div>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
    </QueryClientProvider>
  );
}

export default App;
